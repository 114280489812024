@import './reset.css';
@import '../../node_modules/react-datepicker/dist/react-datepicker.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap');

:root {
  font-family: sans-serif;
  --max-width: 75rem;

  /* Colors */
  --base-color: #fff;
  --text-color: #535353;
  --link-color: #0069ca;
  --head-text-color: #363636;

  --head-font-family: 'Open Sans';
  --head-font-weight: 800;
  --accent-color: #F00;
  color: var(--text-color)
}

a {
  color: inherit;
  text-decoration: inherit;
}
